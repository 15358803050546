html {
    background-color: #f9f9f9;
}

/* width */
::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
}

#root {
    overflow-y: hidden;
    font-family: 'Nunito', sans-serif;
}

// Modified border-radius for outlined TextField
// Used for all form input fields except for table filter fields (Search and Sort by)
.MuiOutlinedInput-root {
    border-radius: 15px !important;
}

.MuiSnackbar-root {
    z-index: 10001 !important;
}

#tidio-chat-iframe {
    bottom: 65px !important;
}

.common-styled-select {
    svg:not(.no-overrides) {
        background: #ebebeb !important;
        border-radius: 7px !important;
        color: #111113 !important;
    }
}

.container-wrapper {
    width: 100%;

    .app-bar {
        background-color: transparent;
        box-shadow: none;

        .toolbar {
            padding-right: 20px;
            padding-top: 20px;

            .title {
                flex-grow: 1;
                font-weight: 700;
                font-size: 24px;
                color: #303030;
                font-family: 'Nunito', sans-serif;
            }

            .profile {
                background: #ffffff;
                border-radius: 30px;
                padding: 5px 5px 5px 8px;
                display: flex;
                align-items: center;

                .MuiAvatar-root {
                    margin-right: 10px;
                }
            }

            button {
                .MuiAvatar-root {
                    background-color: #fff;
                }

                svg {
                    color: #545454;
                }

                .BaseBadge-badge {
                    background-color: #ff3e3e;
                    min-width: 5px;
                    height: 7px;
                    top: 10px;
                    right: 13px;
                    padding: 0 4px;
                }
            }
        }
    }

    .content {
        flex-grow: 1;
        z-index: 1000;
        padding-bottom: 20px;

        .spacer {
            margin-top: 30px;
        }

        .container {
            .orders-status-cont {
                h2 {
                    font-family: 'Nunito', sans-serif;
                    font-weight: 700;
                    font-size: 24px;
                }

                .boxes {
                    display: flex;
                    flex-direction: row;

                    .box {
                        margin-right: 10px;
                        text-align: center;
                        background: #ffffff;
                        box-shadow: 0px 40px 60px rgba(128, 144, 155, 0.25);
                        border-radius: 16px;
                        color: #363636;
                        padding: 25px 30px;

                        h4 {
                            font-weight: 700;
                            font-size: 24px;
                            margin: 0;
                            font-family: 'Nunito', sans-serif;
                            color: #27a857;
                        }

                        p {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 25px;
                            margin: 0;
                            font-family: 'Nunito', sans-serif;
                        }
                    }
                }
            }
        }

        .table-wrapper {
            .MuiPaper-root {
                .MuiTableContainer-root {
                    background-color: transparent;
                    border-radius: 30px 30px 0px 0px;
                    overflow-x: auto;

                    .MuiTableHead-root {
                        th {
                            border: none;
                            font-weight: 500;
                            font-size: 16px;
                            font-family: 'Inter', sans-serif;
                            color: #6b6f7b;
                        }
                    }

                    tbody {
                        tr {
                            &.preview-desktop-screen {
                                td {
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: #6b6f7b;
                                    padding: 12px;

                                    .sub-label {
                                        font-size: 14px;
                                        margin-top: 3px;
                                    }
                                }
                            }

                            td {
                                border: none;

                                &.status-col {
                                    p {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    .Completed {
                                        color: #34e692;
                                    }

                                    .Refunded {
                                        color: #ff9548;
                                    }

                                    .Processing {
                                        color: #48bdff;
                                    }

                                    .Progress {
                                        color: #48bdff;
                                    }

                                    .Pending {
                                        color: #c9c9c9;
                                    }
                                }

                                .remove {
                                    background-color: #1aa928;
                                    color: #fff;
                                    border-radius: 4px;
                                }

                                .add {
                                    color: #e1e1e1;
                                    border: 2px solid #e1e1e1;
                                }

                                .admin-details-container {
                                    display: flex;
                                    flex-direction: row;
                                    width: 100%;

                                    .video-preview {
                                        width: 30%;
                                        text-align: center;
                                        padding: 10px;

                                        h4 {
                                            font-weight: 500;
                                            font-size: 14px;
                                            color: #2d2d2d;
                                            font-family: 'Inter', sans-serif;
                                        }

                                        iframe {
                                            border-radius: 10px;
                                            width: 100%;
                                        }
                                    }

                                    .order-information {
                                        padding: 10px;
                                        width: 80%;

                                        .title {
                                            font-weight: 500;
                                            font-size: 14px;
                                            font-family: 'Inter', sans-serif;
                                            color: #656565;
                                            margin: 0;
                                        }

                                        .information-detail-column {
                                            display: flex;
                                            align-items: row;
                                            justify-content: space-between;

                                            .labeled-desc {
                                                width: 50%;
                                                &.width-100 {
                                                    width: 100%;
                                                }
                                                &.width-60 {
                                                    width: 60%;
                                                }
                                                &.width-50 {
                                                    width: 50%;
                                                }
                                                &.width-40 {
                                                    width: 40%;
                                                }
                                            }
                                        }

                                        hr {
                                            margin: 30px 0;
                                        }

                                        .labeled-desc {
                                            p:first-of-type {
                                                color: #bfbfbf;
                                                font-weight: 500;
                                                font-size: 14px;
                                                font-family: 'Inter', sans-serif;
                                            }

                                            p:last-child {
                                                color: #6b6f7b;
                                                font-weight: 500;
                                                font-size: 14px;
                                                font-family: 'Inter', sans-serif;
                                            }
                                        }

                                        .see-more {
                                            background-color: #eeeeee;
                                            border-radius: 9px;
                                            padding: 6px;
                                            cursor: pointer;

                                            span {
                                                color: #848484;
                                                font-weight: 500;
                                                font-size: 14px;
                                                text-decoration: underline;
                                            }
                                        }
                                    }

                                    .actions {
                                        width: 30%;
                                        padding: 20px;
                                        background: rgba(171, 181, 186, 0.05);

                                        .select-container {
                                            margin-bottom: 40px;
                                        }

                                        .MuiBox-root {
                                            p.title {
                                                color: #000000;
                                                font-weight: 500;
                                                font-size: 14px;
                                                font-family: 'Inter', sans-serif;
                                                padding-bottom: 5px;
                                                font-weight: bold;
                                            }
                                        }

                                        .upload-container {
                                            background: #ffffff;
                                            border: 0.5px dashed #7f7f7f;
                                            border-radius: 15px;
                                            padding: 10px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .upload {
                                                display: flex;
                                                align-items: center;
                                            }

                                            svg {
                                                color: #abb5ba;
                                                margin-right: 10px;
                                            }

                                            p {
                                                color: #abb5ba;
                                                margin: 0;
                                                font-size: 12px;
                                                font-family: 'Inter', sans-serif;
                                            }
                                        }

                                        .upload-loading {
                                            display: flex;
                                            justify-content: between;
                                            align-items: center;
                                            margin-top: 20px;
                                            padding-bottom: 20px;
                                            margin-bottom: 20px;
                                            border-bottom: 1px solid #dfdfdf;
                                            svg {
                                                color: #abb5ba;
                                            }

                                            p {
                                                margin: 0;
                                                color: #6b6f7b;
                                                font-size: 12px;
                                                font-family: 'Inter', sans-serif;
                                                display: flex;
                                                flex-direction: row;
                                                padding-bottom: 5px;

                                                .close {
                                                    background-color: #ff3e3e;
                                                    border-radius: 100px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    padding: 3px;
                                                    margin-left: 5px;

                                                    svg {
                                                        color: #fff;
                                                        height: 12px;
                                                        width: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .details-container {
                                    .video-preview {
                                        width: 30%;
                                        text-align: center;
                                        padding: 10px 35px 10px 0px;

                                        h4 {
                                            font-weight: 500;
                                            font-size: 14px;
                                            color: #2d2d2d;
                                            font-family: 'Inter', sans-serif;
                                        }

                                        iframe {
                                            border-radius: 10px;
                                            width: 100%;
                                        }
                                    }

                                    .service-container {
                                        width: 100%;
                                    }

                                    .service-details {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: wrap;

                                        &:last-child:not(:first-child) {
                                            padding-top: 30px;
                                        }

                                        .detail {
                                            flex-grow: 1;
                                            flex-shrink: 1;
                                            flex-basis: 0;

                                            &:last-child:not(:first-child) {
                                                padding-left: 30px;
                                            }

                                            h5 {
                                                margin: 0;
                                                font-weight: 500;
                                                font-size: 14px;
                                                font-family: 'Inter', sans-serif;
                                                color: #bfbfbf;
                                                margin-bottom: 15px;
                                            }

                                            span {
                                                font-family: 'Inter', sans-serif;
                                                font-weight: 500;
                                                font-size: 14px;
                                                color: #6b6f7b;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pagination {
                font-size: 14px;
                margin-top: 20px;
            }
        }
    }

    .download.issue {
        color: #27a857 !important;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
        svg {
            padding-right: 5px;
        }
    }
}

.new-order {
    .container {
        .tabs-container {
            background-color: #fff;
            border-radius: 40px;
            margin-bottom: 20px;
            height: 100%;
            .JoyTabList-root {
                .JoyTab-root {
                    border-bottom: 1px solid #dedede;
                    border-radius: 0px;
                    -webkit-margin-start: 0;
                    margin-inline: 0;
                    padding: 20px 10px;
                    &.Joy-selected {
                        border-bottom: 4px solid #ff3e3e;
                    }
                }
            }
        }
        .order-form {
            height: 100%;

            .MuiFormControl-root {
                svg.MuiSvgIcon-root.MuiSelect-icon,
                .up-select-icon {
                    background: #ebebeb !important;
                    border-radius: 7px !important;
                    color: #111113 !important;
                }
            }

            .ad-video {
                display: block;
                text-align: center;

                img.laptop {
                    width: 90%;
                    margin-top: -35px;
                }

                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    margin-top: 20px;

                    img {
                        margin-right: 10px;
                    }

                    span {
                        color: rgba(0, 0, 0, 0.5);
                        font-weight: 500;
                        font-size: 14px;
                        font-family: 'Inter', sans-serif;
                    }
                }
            }

            form {
                .form-inputs-container {
                    padding: 30px;
                }

                label {
                    font-weight: 600;
                    font-size: 14px;
                    font-family: 'Inter', sans-serif;
                    color: #000000;
                    display: block;
                }

                small {
                    float: right;
                    font-weight: 500;
                    font-size: 12px;
                    font-family: 'Inter', sans-serif;
                    color: #f7684a;
                    display: flex;
                    justify-content: baseline;
                    text-decoration: underline;
                }

                h4 {
                    font-weight: 600;
                    font-size: 16px;
                    color: #000000;
                    font-family: 'Inter', sans-serif;
                }

                .image-list {
                    .upload {
                        height: 100%;
                        width: 100%;
                        border: 1px dashed #abb5ba;
                        border-radius: 15px;
                        color: #abb5ba;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        p {
                            font-weight: 500;
                            font-size: 13px;
                            font-family: 'Inter', sans-serif;
                        }
                    }

                    .upload.error {
                        border: 1px dashed #d32f2f;
                    }

                    li {
                        border: 3px solid #fff;
                        opacity: 0.5;

                        img {
                            border-radius: 15px;
                            max-height: 180px;
                        }

                        img:hover {
                            outline: 3px solid #f23a3a;
                            opacity: 1;
                        }
                    }
                }

                .desc {
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: 500;
                    font-size: 10px;
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    margin-top: 10px;
                }

                .item {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;

                    &:last-child {
                        margin-left: 20px;
                    }
                }

                .MuiFormControl-root {
                    .MuiInputBase-input {
                        font-weight: 400;
                        font-size: 14px;
                        font-family: 'Inter', sans-serif;
                    }
                }

                .countries {
                    .countries-selected {
                        background-color: #0091ea;
                        font-size: 12px;
                        border-radius: 9px;
                        margin-right: 5px;

                        svg {
                            color: #fff;
                        }

                        .MuiChip-deleteIcon {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .order-summary {
            background-color: #fff;
            border-radius: 40px;
            padding: 30px;

            h4 {
                font-weight: 600;
                font-size: 16px;
                color: #000000;
                font-family: 'Inter', sans-serif;
            }

            ul {
                font-family: 'Inter', sans-serif;

                li {
                    padding-left: 0px;
                    padding-right: 0px;

                    div {
                        span {
                            color: rgba(0, 0, 0, 0.5);
                            font-weight: 500;
                            font-size: 12px;
                            font-family: 'Inter', sans-serif;
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                            color: #111113;
                            text-align: right;
                            font-family: 'Inter', sans-serif;
                        }

                        &.total {
                            p {
                                color: #27a857;
                                font-weight: 700;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

        .testimony {
            margin-top: 20px;
            .details {
                h2 {
                    margin: 0;
                    font-family: 'Inter', sans-serif;
                    font-weight: 700;
                    font-size: 24px;
                }

                p {
                    font-family: 'Inter', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    margin: 0;

                    &.message {
                        margin-top: 20px;
                    }
                }
                .gradient-bg {
                    background-image: linear-gradient(
                        0deg,
                        #e6476a 27%,
                        #ffffff00 60%
                    );
                    border-radius: 40px;
                    & div.content-rating {
                        padding: 350px 30px 60px 30px;
                        & span.MuiRating-root {
                            color: #faaf00 !important;
                        }
                    }
                }
            }
        }
    }
}

.modal-close {
    position: absolute;
    right: -13px;
    top: -13px;
    cursor: pointer;

    .MuiAvatar-root {
        background-color: #f8451b;
    }

    svg {
        color: #fff;
    }
}

.orders {
    .MuiContainer-root {
        max-width: auto;
    }

    .container {
        .title {
            font-family: 'Nunito', sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: #303030;
        }

        .add-campaign {
            border: 2px dashed #6b6f7b;
            width: 315px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 160px;
            width: 80%;

            p {
                margin: 0;
                color: #6b6f7b;
                font-weight: 500;
                font-size: 16px;
                font-family: 'Inter', sans-serif;
            }

            svg {
                color: #6b6f7b;
                font-size: 30px;
            }
        }

        .card {
            background-color: #fff;
            background: #ffffff;
            box-shadow: 0px 40px 60px rgba(128, 144, 155, 0.25);
            border-radius: 16px;
            padding: 7px;

            .top {
                border-radius: 9px;

                &.active {
                    background: #ff3e3e;
                }

                &.pending {
                    background: #a9a9a9;
                }

                &.on-hold {
                    background: #a9a9a9;
                }

                &.cancelled {
                    background: #a9a9a9;
                }

                &.completed {
                    background: #1aa928;
                }

                &.refunded {
                    background: #ff9548;
                }

                .MuiCardHeader-content {
                    span {
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        font-size: 14px;
                        color: #fff;
                    }
                }

                .MuiCardHeader-action {
                    span {
                        font-family: 'Inter', sans-serif;
                        font-weight: 500;
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }

            .bottom {
                .MuiCardHeader-content {
                    span {
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        font-size: 14px;
                        color: #000;
                    }
                }

                .MuiCardHeader-action {
                    cursor: pointer;

                    span {
                        font-family: 'Inter', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        text-decoration: underline;
                        color: #27a857;
                    }
                }
            }

            .MuiCardContent-root {
                p {
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    color: #303030;
                    font-weight: 500;
                    font-size: 12px;

                    &.label {
                        font-weight: 400;
                        font-size: 12px;
                        color: #c9c9c9;

                        span {
                            color: #303030;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.deposit-modal-container {
    .MuiAvatar-root {
        position: absolute;
        right: -9px;
        top: 175px;
        background-color: #f8451b;
        cursor: pointer;

        svg {
            color: #fff;
        }
    }

    .payment-options-container {
        &.desktop {
            background: url(./assets/images/ray-bg-left.png),
                linear-gradient(165.77deg, #fc4404 1.63%, #e54870 95.75%);
            background-position: left bottom;
            background-repeat: no-repeat;
            border-radius: 40px;
        }

        h2 {
            margin: 0;
            text-align: center;
            color: #fff;
            font-family: 'Nunito', sans-serif;
            font-weight: 700;
            font-size: 34px;
            padding-top: 35px;
        }

        p.desc {
            font-family: 'Inter', sans-serif;
            text-align: center;
            color: #fff;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            margin-top: 30px;
            margin-bottom: 15px;
        }

        &.mobile p.desc {
            color: #000;
            margin-top: 10px;
        }

        .cards {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            div {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 7px;

                img {
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                }

                &.active {
                    img {
                        border: 2px solid #17e383;
                        filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
                    }
                    p {
                        font-weight: 700;
                        font-size: 11px;
                    }
                }

                p {
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 10px;
                    color: #fff;
                }
            }
        }

        &.mobile .cards p {
            color: #000 !important;
        }
    }

    .credit-form-container {
        background-color: #fff;
        border-radius: 0 40px 40px 0;

        &.desktop {
            padding: 50px 70px;
        }
        &.mobile {
            margin-top: 39px;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            color: #303030;
            font-family: 'Nunito', sans-serif;
            margin: 0;
            padding-bottom: 20px;
        }

        .MuiFormControl-root {
            .MuiInputBase-input {
                font-weight: 400;
                font-size: 14px;
                font-family: 'Inter', sans-serif;
                border-radius: 15px;
                border: 1px solid rgba(17, 17, 19, 0.2);
            }
        }

        label {
            font-weight: 500;
            font-size: 14px;
            padding-bottom: 5px;
            font-family: 'Inter', sans-serif;
            color: #000000;
            display: block;
        }

        .checkbox {
            .MuiTypography-root {
                font-weight: 500;
                font-size: 12px;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;

            button {
                background-color: #27a857;
                border-radius: 40px;
                font-weight: 500;
                font-size: 14px;
                box-shadow: none;
                padding: 10px 20px;
                color: #fff;
                margin-top: 30px;
                margin-bottom: 20px;
            }

            .policy {
                display: block;
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                font-family: 'Inter', sans-serif;
                margin: 0;
                color: #000;
            }
        }
    }

    .crypto-payment-container {
        background-color: #fff;
        border-radius: 0 40px 40px 0;

        &.desktop {
            padding: 80px 70px;
        }
        &.mobile {
            margin-top: 39px;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            color: #303030;
            font-family: 'Nunito', sans-serif;
            margin: 0;
        }

        p.desc {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            font-size: 18px;
            color: #000000;
        }

        .MuiFormControl-root {
            .MuiInputBase-input {
                font-weight: 400;
                font-size: 14px;
                font-family: 'Inter', sans-serif;
                border-radius: 15px;
                border: 1px solid rgba(17, 17, 19, 0.2);
            }
        }

        label {
            font-weight: 500;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            color: #000000;
            display: block;
        }

        .button-container {
            display: flex;
            flex-direction: column;

            button {
                margin-top: 15px;
                margin-bottom: 20px;
            }

            .policy {
                display: block;
                text-align: center;
                font-weight: 500;
                font-size: 12px;
                font-family: 'Inter', sans-serif;
                margin: 0;
                color: #000;
            }
        }
    }

    .bank-transfer-container {
        background-color: #fff;
        border-radius: 0 40px 40px 0;
        &.mobile {
            margin-bottom: 150px;
        }

        .MuiFormControl-root {
            margin-bottom: 10px;
        }

        label {
            font-weight: 500;
            font-size: 14px;
            padding-bottom: 5px;
            font-family: 'Inter', sans-serif;
            color: #000000;
            display: block;
        }

        &.mobile {
            margin-top: 39px;
        }

        &.desktop {
            padding: 50px 70px;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            color: #303030;
            font-family: 'Nunito', sans-serif;
            margin: 0;
        }

        p.desc {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
        }

        .account-details {
            background: #fff4f2;
            border: 1px solid #fff4f2;
            border-radius: 20px;
            padding: 25px;

            ul {
                font-family: 'Inter', sans-serif;
                padding: 0;

                li {
                    padding: 0px;

                    div {
                        span {
                            color: #6b6f7b;
                            font-weight: 500;
                            font-size: 14px;
                            font-family: 'Inter', sans-serif;
                        }

                        p {
                            font-weight: 500;
                            font-size: 14px;
                            color: #000;
                            text-align: right;
                            font-family: 'Inter', sans-serif;
                        }
                    }
                }
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;

            button {
                margin-bottom: 20px;
            }

            .policy {
                display: block;
                text-align: center;
                font-weight: 500;
                font-size: 12px;
                font-family: 'Inter', sans-serif;
                margin: 0;
                color: #000;
            }
        }
    }
}

.deposit-confirmation-container {
    background-color: #fff;
    border-radius: 40px;

    .MuiAvatar-root {
        position: absolute;
        right: -9px;
        top: -6px;
        background-color: #f8451b;
        cursor: pointer;

        svg {
            color: #fff;
        }
    }

    .left-payment-container {
        height: 100%;
        border-radius: 40px 0 0 40px;
        background: url(./assets/images/payment-confirmation-bg.png),
            linear-gradient(165.77deg, #fc4404 1.63%, #e54870 95.75%);
        background-position: right;
        background-repeat: no-repeat;
        position: relative;

        img {
            position: absolute;
            left: -60px;
            bottom: 0px;
            width: 120%;
        }
    }

    .modal-details {
        background-color: #fff;
        border-radius: 0 40px 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;

        img {
            max-width: 290px;
        }

        h3 {
            font-weight: 500;
            font-size: 20px;
            font-family: 'Inter', sans-serif;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            font-family: 'Nunito', sans-serif;
            color: #303030;
        }

        p {
            font-weight: 500;
            font-size: 18px;
            font-family: 'Inter', sans-serif;
            text-align: center;

            a {
                color: #27a857;
            }
        }

        .button-container {
            display: block;
            width: 100%;

            button {
                background-color: #27a857;
                border-radius: 40px;
                font-weight: 500;
                font-size: 14px;
                box-shadow: none;
                padding: 10px 20px;
                color: #fff;
                margin-top: 15px;
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }

    &.deposit-failed {
        .left-container {
            .character-failed {
                position: absolute;
                left: -140px;
                height: 70%;
                bottom: 0px;
                z-index: 9999;
            }
        }
    }
}

// GLOBAL MUI MODIFICATION
.MuiPagination-ul {
    .MuiPaginationItem-page.Mui-selected {
        color: #17e383;
        background: none;
    }

    .MuiPaginationItem-circular.MuiPaginationItem-previousNext {
        border: 1px solid #17e383;
        color: #17e383;
    }

    .MuiPaginationItem-previousNext.Mui-disabled {
        color: #e5e5e5;
        border: 1px solid #e5e5e5;
        opacity: 1;
    }
}
.register-body {
    .register-header {
        text-align: center;
        margin-bottom: 37px;
    }
    .login-with-google {
        background: #ffffff;
        border: 1px solid #dedede;
        padding: 11px 26px;
        text-transform: none;
        color: #000000;

        img {
            margin-right: 8px;
            width: 22px;
        }
    }

    .register-form {
        margin-top: 35px;
    }
}

.login-body {
    .login-header {
        text-align: center;
        margin-bottom: 37px;

        .sub-title {
            margin-top: 6px;
            margin-bottom: 38px;
            font-size: 18px;
            color: #000000;
        }

        .login-with-google {
            background: #ffffff;
            border: 1px solid #dedede;
            padding: 11px 26px;
            text-transform: none;
            color: #000000;

            img {
                margin-right: 8px;
                width: 22px;
            }
        }
    }

    .login-form {
        margin-top: 35px;
    }
}

.transaction-history {
    .MuiContainer-root {
        max-width: auto;
    }

    .container {
        .title {
            font-family: 'Nunito', sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: #303030;
        }

        .transactions-table {
            margin-top: 70px;

            .MuiPaper-root {
                background-color: transparent;
                box-shadow: none;

                .toolbar {
                    background-color: transparent;

                    h3 {
                        font-weight: 700;
                        font-size: 24px;
                        font-family: 'Nunito', sans-serif;
                    }

                    .form {
                        display: flex;
                        flex-direction: row;
                        width: 70%;

                        .item {
                            flex-grow: 1;
                            flex-shrink: 1;
                            flex-basis: 0;

                            &:first-of-type {
                                margin-right: 20px;
                            }
                        }
                    }
                }

                .MuiTableContainer-root {
                    background-color: transparent;
                    border-radius: 30px 30px 0px 0px;

                    thead {
                        background-color: #fff;

                        &:after {
                            content: '@';
                            display: block;
                            line-height: 10px;
                            text-indent: -99999px;
                            background-color: #f9f9f9;
                        }

                        th {
                            border: none;
                            font-weight: 500;
                            font-size: 18px;
                            font-family: 'Inter', sans-serif;
                            color: #6b6f7b;
                        }
                    }

                    tbody {
                        background-color: #fff;

                        tr {
                            &.preview {
                                td {
                                    font-weight: 500;
                                    font-size: 14px;
                                    font-family: 'Inter', sans-serif;
                                    color: #6b6f7b;
                                }
                            }

                            td {
                                border: none;
                            }
                        }
                    }
                }
            }

            .table-info {
                margin-top: 20px;
            }
        }
    }
}

.admin-screen {
    .container {
        padding-bottom: 50px;
    }
}

.balance-container {
    background: #27a857;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.25);
    border-radius: 16px;
    padding: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        p {
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }

    p.amount {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
    }

    h1 {
        color: #fff;
        font-weight: 600;
        font-size: 32px;
        font-family: 'Inter', sans-serif;
    }
}

.average-sales-container {
    background: #fff;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.25);
    border-radius: 16px;
    padding: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        p {
            color: #878997;
            font-weight: 400;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }

    p.amount {
        color: #565a6d;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
    }

    h1 {
        color: #0e132f;
        font-weight: 600;
        font-size: 32px;
        font-family: 'Inter', sans-serif;
    }
}

.average-revenue-container {
    background: #fff;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.25);
    border-radius: 16px;
    padding: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        p {
            color: #878997;
            font-weight: 400;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }

    p.amount {
        color: #565a6d;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
    }

    h1 {
        color: #0e132f;
        font-weight: 600;
        font-size: 32px;
        font-family: 'Inter', sans-serif;
    }
}

.revenue-statistics-container {
    background: #fff;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.25);
    border-radius: 16px;
    padding: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-weight: 600;
            font-size: 17px;
            color: #0e132f;
            display: inline;
        }

        p {
            color: #878997;
            font-weight: 400;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }
}

.typeof-views-container {
    background: #fff;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.25);
    border-radius: 16px;
    padding: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // padding-bottom: 30px;

        h2 {
            font-weight: 600;
            font-size: 17px;
            color: #0e132f;
            display: inline;
        }

        p {
            color: #878997;
            font-weight: 400;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }
}

#graphs-popover {
    .MuiPaper-root {
        .MuiList-root {
            padding: 0;

            .MuiListItemButton-root {
                padding: 3px 30px;

                .MuiTypography-root {
                    font-size: 14px;
                }
            }
        }
    }
}

#more-popover {
    .MuiPaper-root {
        .MuiList-root {
            padding: 0;

            .MuiListItemButton-root {
                padding: 3px 10px;

                svg {
                    margin-right: 5px;
                    height: 15px;
                    width: 15px;

                    &.resend {
                        transform: rotate(-50deg);
                    }
                }

                &:hover {
                    background: #ffe2e2;

                    svg {
                        color: #ff3e3e;
                    }
                }

                .MuiTypography-root {
                    font-size: 14px;
                    color: #6b6f7b;
                }
            }
        }
    }
}

// Media Queries
@media (min-width: 1200px) {
    .MuiContainer-root {
        max-width: unset !important;
    }
}

// Slowly cleaning up the styles above
// Transferring here all common components styles
button.upviews-badge {
    .MuiAvatar-root {
        background-color: #f0f2f5 !important;
    }

    svg {
        color: #545454 !important;
    }

    .BaseBadge-badge {
        background-color: #ff3e3e;
        min-width: 5px;
        height: 7px;
        top: 10px;
        right: 13px;
        padding: 0 4px;
    }

    &.close .BaseBadge-badge {
        background-color: transparent;
    }
}

// Modified border-radius for outlined TextField
// Used for all form input fields except for table filter fields (Search and Sort by)
.MuiOutlinedInput-root {
    border-radius: 15px !important;
}

.upview-form-control {
    svg.MuiSvgIcon-root {
        margin-left: -5px;
    }

    .MuiOutlinedInput-root {
        border-radius: 7px !important;
        background-color: #fff !important;
    }

    .MuiOutlinedInput-input {
        margin-left: 3px;
    }
}

.statistics {
    .box {
        margin-right: 10px;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 40px 60px rgba(128, 144, 155, 0.25);
        border-radius: 16px;
        color: #363636;
        padding: 25px 30px;

        h4 {
            font-weight: 700;
            font-size: 24px;
            margin: 0;
            font-family: 'Nunito', sans-serif;
            color: #27a857;
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin: 0;
            font-family: 'Nunito', sans-serif;
        }
    }
}

.drawer {
    width: 240px;

    .MuiPaper-root {
        width: inherit;
        white-space: nowrap;
        background: #ffffff;
        box-shadow: 1px 0px 2px rgba(128, 128, 128, 0.1);
        border: none;
    }

    .logo {
        display: block;
        padding: 35px 0;
        margin: 0 auto;
    }

    .MuiList-root {
        padding: 10px 28px;

        .MuiButtonBase-root {
            margin-bottom: 5px;
            border-radius: 30px;
            padding: 6px 8px;

            span {
                font-family: 'Nunito', sans-serif;
            }

            &.active {
                background-color: #ff3e3e;

                .MuiAvatar-root {
                    background-color: #fff;
                }

                svg path {
                    fill: #ff3e3e;

                    &.right-icon {
                        color: #fff;
                    }
                }

                span {
                    font-weight: 600;
                    font-size: 14px;
                    font-style: normal;
                    color: #fff;
                }
            }

            &.has-sub-menu.active {
                background-color: #ffe2e2;

                svg {
                    &.right-icon {
                        color: #ff3e3e;
                    }
                }

                span {
                    color: #ff3e3e;
                }
            }

            .MuiAvatar-root {
                background-color: transparent;
            }

            svg {
                color: #abb5ba;
            }

            span {
                font-weight: 600;
                font-size: 14px;
                font-style: normal;
                color: #abb5ba;
            }
        }
    }

    .sub-menu-list {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;

        .MuiListItemButton-root {
            padding: 10px 15px;

            .MuiListItemIcon-root {
                min-width: 36px;
                svg {
                    path {
                        fill: #abb5ba;
                    }
                }
                span.settings-api {
                    padding: 3px 5px 1px;
                    background-color: #abb5ba;
                    border-radius: 6px;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        &.active {
            .MuiListItemButton-root {
                background-color: #ff3e3e;

                svg {
                    path {
                        fill: #fff;
                    }
                    &.right-icon {
                        color: #fff;
                    }
                }

                span.settings-api {
                    background-color: #fff;
                    svg {
                        path {
                            fill: #ff3e3e;
                        }
                    }
                }

                span {
                    font-weight: 600;
                    font-size: 14px;
                    font-style: normal;
                    color: #fff;
                }
            }
        }
    }
}

.support-form {
    background: #ffffff;

    .MuiBox-root {
        label {
            font-weight: 500;
            font-size: 14px;
            color: #000000;
        }

        .MuiFormControl-root {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        textarea {
            margin-top: 10px;
            border: 1px solid rgba(17, 17, 19, 0.2);
            border-radius: 15px;
            padding: 10px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .submit-container {
        display: flex;

        .captcha {
            width: 70%;

            &.mobile {
                width: 100%;
                position: relative;
                border: 1px solid #ededed;
                background: #f9f9f9;
                border-radius: 4px;
                padding: 0;

                #topHider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 2px !important;
                    width: 100%;
                    background-color: #f9f9f9;
                }

                #rightHider {
                    position: absolute;
                    top: 0;
                    left: 295px;
                    height: 100% !important;
                    width: 15px;
                    background-color: #f9f9f9;
                }

                #leftHider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100% !important;
                    width: 2px;
                    background-color: #f9f9f9;
                }

                #bottomHider {
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    height: 2px !important;
                    width: 100%;
                    background-color: #f9f9f9;
                }
            }
        }

        .btn-container {
            .submit-button {
                background-color: #27a857;
                border-radius: 40px;
                font-weight: 500;
                font-size: 16px;
                box-shadow: none;
                padding: 10px 20px;
                color: #fff;
                display: block;
                width: 100%;
            }
        }
    }
}

.ads-container {
    background: #ffffff;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    padding-bottom: 15px;

    .greetings {
        background: linear-gradient(114.7deg, #3c5bff 0%, #1d31b9 100%);
        padding: 30px 40px;
        padding-bottom: 60px;
        border-radius: 40px 40px 0 0;

        h1 {
            font-weight: 400;
            font-size: 32px;
            color: #fff;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            color: #fff;
        }
    }

    .start-conversation {
        background-color: #fff;
        margin: 0 15px;
        margin-top: -45px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
            0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        h4 {
            padding: 24px;
            padding-bottom: 0;
        }

        .reply-time {
            padding: 0 24px;

            .conversation-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background: #ffffff;
                border-radius: 3px;

                img {
                    width: 96px;
                    height: 56px;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    color: #737376;
                    margin: 0;

                    b {
                        font-weight: 600;
                        color: #000000;
                        display: flex;
                        align-items: center;

                        svg {
                            height: 20px;
                            color: #27a857;
                        }
                    }
                }
            }
        }

        .send-message-button {
            background-color: #27a857;
            border-radius: 40px;
            font-weight: 500;
            font-size: 14px;
            box-shadow: none;
            padding: 10px 20px;
            color: #fff;
            display: block;
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 16px;

            svg {
                margin-right: 15px;
            }
        }

        .MuiDivider-root {
            margin-top: 16px;
        }

        .see-all {
            color: #27a857;
            font-weight: 400;
            font-size: 14px;
            padding: 16px 24px;
            margin: 0;
        }
    }

    .search-container {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
            0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 24px;
        margin: 0 15px;
        margin-top: 20px;

        label {
            color: #000000;
            font-weight: 600;
            font-size: 16px;
        }

        .MuiFormControl-root {
            margin-top: 10px;
        }
    }

    .banner-container {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
            0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 24px;
        margin: 0 15px;
        margin-top: 20px;

        img {
            width: 100%;
        }
    }
}

// Global input outline
.MuiOutlinedInput-root.Mui-focused {
    fieldset {
        border: 3px solid #ec4753 !important;
    }
}

textarea:focus {
    outline: 3px solid #ec4753 !important;
}

.chips-container {
    .MuiChip-root {
        background-color: #9ad9ff;
        border-radius: 9px;
        padding: 6px;
        margin-right: 7px;
        margin-bottom: 10px;

        span {
            color: #37769c;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.reactour__popover {
    max-width: 410px !important;
}
